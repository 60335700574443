<template>
  <ActiveList
    :config="config"
    :custom-query="{
      role: 3,
      includepartners: false,
    }"
  />
</template>

<script>
import ActiveList from './ActiveList.vue'

import storeModule from './activeStoreModule'

export default {
  components: {
    ActiveList,
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'companies',
        endpoint: 'companies',
        route: 'companies',
        filtersOpen: true,
        title: {
          single: this.$i18n.t('Seller'),
          plural: this.$i18n.t('Sellers'),
        },
        urlEshop:'seller'
      }
    },
  },
}
</script>
